import React from "react";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";
import {
  StyledAdoptNoDIY, StyledItem,
  StyledItemDescription,
  StyledItemImage, StyledItemsScroller,
} from "./style";

export default ({ items, theme = "black" }) => {

  return (
    <StyledAdoptNoDIY isWhite={theme=="white"}>
      <StyledGridRow isFullMobile>
        <Scroller>
          <StyledItemsScroller itemCount={items.length}>
            {items.map((item, index) => (
              <StyledItem key={index} itemCount={items.length}>
                <StyledItemImage bg={item.imageSrc} />
                <StyledItemDescription
                  dangerouslySetInnerHTML={{ __html: item.caption }}
                />
              </StyledItem>
            ))}
          </StyledItemsScroller>
        </Scroller>
      </StyledGridRow>
    </StyledAdoptNoDIY>
  );
};
